@yellow: #fab808;
@red: #d21830;
@blue: #4392b7;

@import url(https://fonts.googleapis.com/css?family=Exo+2:300italic,400italic,500italic,600italic&subset=latin,cyrillic);
// COMMON
body {
  font-family: 'Exo 2', sans-serif;
  font-style: italic;
  font-weight:normal;
  font-size:15px;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-weight: 500;
}
h3 {
  font-size: 20px;
}
.navbar-toggle {
  float:left;
}
label {
  &.error {
    color: lighten(@red, 10%);
    font-size:12px;
    font-weight: 300;
  }
}
// MODALS
.modal {
  .modal-content {
    background: #f9f9f9;
    textarea {
      height: 120px;
    }
  }
}
// FORMS


// BUTTONS
.btn {
  text-transform: uppercase;
  font-style: italic;
  &:focus {
    outline: none;
    &:active {
      outline: none;
    }
  }
}

// HEADER

header {
  -webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
  position:relative;
  z-index:1;
  .top {
    position:relative;
    border-bottom:solid 5px #f0f0f0;
    background: @yellow;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
    z-index:2;
    color: @red;
    .logo {
      margin-top: 130px;
    }
    .truck {
      margin-top: 10px;
    }
    .service {
      margin-top: 100px;
      h4 {
        text-transform:uppercase;
        font-weight:600;
      }
      p {
        font-size:1.2em;
      }
    }
    .background {
      background: transparent;
      height: 46px;
      right: 0;
      position: absolute;
      top: 0;
      width: 80%;
      z-index: 0;
      border-top: 46px solid #f0f0f0;
      border-left: 46px solid transparent;
    }
  }
  .navbar {
    border: none;
    text-transform:uppercase;
    font-weight:500;
    .social {
      margin-top: 8px;
      a {
        color:#fff;
        display:block;
        height: 26px;
        line-height: 26px;
        text-align: center;
        width: 26px;
        -webkit-transition: background .2s linear;
        transition: background .2s linear;
        border-radius: 13px;
        -moz-border-radius: 13px;
        -webkit-border-radius: 13px;
        &:hover {
          -webkit-transition: background .2s linear;
          transition: background .2s linear
        }
        &.facebook {
          background: #2871af;
          &:hover {
            background: lighten(#2871af, 10%);
          }
        }
        &.twitter {
          background: #3bcee4;
          &:hover {
            background: lighten(#3bcee4, 10%);
          }
        }
      }
      i {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .nav > li > a {
      padding: 12px 13px;
    }
  }
}

// MAIN
section#content {
  min-height:400px;
  padding-bottom: 40px;
  #homeBanner {
    margin-top:-20px;
    margin-bottom: 20px;
    z-index:0;
    img {
      max-width: 100%;
    }
  }
  .box-shadow {
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 45px 50px;
    text-align:center;
    background: #f0f0f0;
    -webkit-box-shadow: 10px 10px 0px 0px #f0f0f0;
    -moz-box-shadow: 10px 10px 0px 0px #f0f0f0;
    box-shadow: 10px 10px 0px 0px darken(#f0f0f0, 5%), 20px 20px 0px 0px darken(#f0f0f0, 10%);
  }
  .home {
    padding: 100px 0;
  }
}


// FOOTER
footer {
  background: @blue;
  padding-top: 2em;
  color: #fff;
  .bottom {
    line-height:38px;
    margin:0;
    padding:0;
    margin-top: 2em;
    overflow:hidden;
    color: @blue;
    background: darken(@blue, 10%);
    font-style:normal;
    a {
      color: @blue;
    }
  }
  .form-inline {
    .form-control {
      border:none;
      box-shadow:none;
      width: 60% !important;
      display:block !important;
      float:left;
    }
    button {
      width:40% !important;
      display:block;
    }
  }
}